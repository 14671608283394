// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Rubik-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Rubik-Light.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Rubik";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

#block13 {
  font-family: "Rubik";
  font-weight: bold;

  color: black;
  font-size: 3vw;
  -webkit-text-stroke: 1px black;
}
a {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/Home/Block13.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB;0DACwC;EACxC,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;;EAEjB,YAAY;EACZ,cAAc;EACd,8BAA8B;AAChC;AACA;EACE,qBAAqB;AACvB","sourcesContent":["@font-face {\n  font-family: \"Rubik\";\n  src: url(\"Rubik-Light.woff2\") format(\"woff2\"),\n    url(\"Rubik-Light.woff\") format(\"woff\");\n  font-weight: 300;\n  font-style: normal;\n  font-display: swap;\n}\n\n#block13 {\n  font-family: \"Rubik\";\n  font-weight: bold;\n\n  color: black;\n  font-size: 3vw;\n  -webkit-text-stroke: 1px black;\n}\na {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
