import React from "react";

import "./Block13.css";

function Block13() {
  return (
    <a href="mailto:mica.oszczakiewicz@gmail.com">
      <div id="block13">GET IN TOUCH</div>
    </a>
  );
}

export default Block13;
