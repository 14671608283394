// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#block4 {
  width: 25vw;
  height: 100%;
  /* align-items: start; */
}
#player {
  /* width: 150%; */
  transform: translate(-20rem -20rem);
}
`, "",{"version":3,"sources":["webpack://./src/Home/Block4.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;AAC1B;AACA;EACE,iBAAiB;EACjB,mCAAmC;AACrC","sourcesContent":["#block4 {\n  width: 25vw;\n  height: 100%;\n  /* align-items: start; */\n}\n#player {\n  /* width: 150%; */\n  transform: translate(-20rem -20rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
