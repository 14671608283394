// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#flex {
  display: flex;
  flex-direction: column;
}
#sweSplit {
  display: flex;
  flex-direction: row;
}
#sweNav {
  display: flex;
  flex-direction: column;

  background-color: black;
  height: 100vh;
  width: 25vw;
}
#sweViewer {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(178, 178, 178, 1) 0%,
    rgba(168, 193, 192, 1) 32%,
    rgba(128, 255, 247, 1) 100%
  );
  height: 100vh;
  width: 75vw;
}
.navProj {
  width: 25vw;
  height: 10vh;
  background-color: black;
}

#sweNav button {
  font-family: "Rubik";
  font-weight: bold;
  font-size: 1.9vw;
  background-color: black;
  color: white;
  border: none;
}

#sweNav button:hover {
  background-color: #ffaee9;
  color: black;
}
#port {
  margin-top: 7vh;
}
`, "",{"version":3,"sources":["webpack://./src/SWE/swe.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;;EAEtB,uBAAuB;EACvB,aAAa;EACb,WAAW;AACb;AACA;EACE,8BAA8B;EAC9B;;;;;;GAMC;EACD,aAAa;EACb,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,eAAe;AACjB","sourcesContent":["#flex {\n  display: flex;\n  flex-direction: column;\n}\n#sweSplit {\n  display: flex;\n  flex-direction: row;\n}\n#sweNav {\n  display: flex;\n  flex-direction: column;\n\n  background-color: black;\n  height: 100vh;\n  width: 25vw;\n}\n#sweViewer {\n  background: rgb(255, 255, 255);\n  background: linear-gradient(\n    0deg,\n    rgba(255, 255, 255, 1) 0%,\n    rgba(178, 178, 178, 1) 0%,\n    rgba(168, 193, 192, 1) 32%,\n    rgba(128, 255, 247, 1) 100%\n  );\n  height: 100vh;\n  width: 75vw;\n}\n.navProj {\n  width: 25vw;\n  height: 10vh;\n  background-color: black;\n}\n\n#sweNav button {\n  font-family: \"Rubik\";\n  font-weight: bold;\n  font-size: 1.9vw;\n  background-color: black;\n  color: white;\n  border: none;\n}\n\n#sweNav button:hover {\n  background-color: #ffaee9;\n  color: black;\n}\n#port {\n  margin-top: 7vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
