import React from "react";

function Gallery() {
  return (
    <div id="flex">
      <h1>gallery</h1>
    </div>
  );
}

export default Gallery;
