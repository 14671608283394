// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container {
  display: flex;

  flex-direction: column;
  height: 15vh;
  width: 80vw;
  gap: 0;
}
.crow1 {
  height: 5vh;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.s1 {
  height: 5vh;
  width: 4vw;
  background-color: #fffd71;
  margin: 0;
}
.s2 {
  height: 5vh;
  width: 4vw;
  background-color: black;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/About/checker1.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,MAAM;AACR;AACA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,UAAU;EACV,yBAAyB;EACzB,SAAS;AACX;AACA;EACE,WAAW;EACX,UAAU;EACV,uBAAuB;EACvB,SAAS;AACX","sourcesContent":["#container {\n  display: flex;\n\n  flex-direction: column;\n  height: 15vh;\n  width: 80vw;\n  gap: 0;\n}\n.crow1 {\n  height: 5vh;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-evenly;\n}\n\n.s1 {\n  height: 5vh;\n  width: 4vw;\n  background-color: #fffd71;\n  margin: 0;\n}\n.s2 {\n  height: 5vh;\n  width: 4vw;\n  background-color: black;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
