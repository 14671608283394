// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Rubik-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Rubik-Light.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Rubik";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
.text {
  font-family: "Rubik";
  font-weight: normal;
  font-size: 3vw;
  margin-bottom: 0.25em;
  margin-top: 20;
}
.text2 {
  font-family: "rubik";
  font-weight: bold;
  font-size: 5vw;
  margin-top: 0;
  margin-bottom: 0;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;
  width: 100vw;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 68%,
    rgba(255, 174, 233, 1) 100%
  );
}
.constructionApp {
  align-items: center;
  text-align: center;
}

.container {
  /* position: absolute; */
  top: 10vh;
  left: 12vw;
  margin: 0;
  height: 75vh;
  width: 75vw;
}
`, "",{"version":3,"sources":["webpack://./src/Home/construction.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB;0DACwC;EACxC,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,cAAc;EACd,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,aAAa;EACb,YAAY;EACZ,8BAA8B;EAC9B;;;;;GAKC;AACH;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,SAAS;EACT,UAAU;EACV,SAAS;EACT,YAAY;EACZ,WAAW;AACb","sourcesContent":["@font-face {\n  font-family: \"Rubik\";\n  src: url(\"Rubik-Light.woff2\") format(\"woff2\"),\n    url(\"Rubik-Light.woff\") format(\"woff\");\n  font-weight: 300;\n  font-style: normal;\n  font-display: swap;\n}\n.text {\n  font-family: \"Rubik\";\n  font-weight: normal;\n  font-size: 3vw;\n  margin-bottom: 0.25em;\n  margin-top: 20;\n}\n.text2 {\n  font-family: \"rubik\";\n  font-weight: bold;\n  font-size: 5vw;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n.flex {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  height: 100vh;\n  width: 100vw;\n  background: rgb(255, 255, 255);\n  background: linear-gradient(\n    180deg,\n    rgba(255, 255, 255, 1) 0%,\n    rgba(255, 255, 255, 1) 68%,\n    rgba(255, 174, 233, 1) 100%\n  );\n}\n.constructionApp {\n  align-items: center;\n  text-align: center;\n}\n\n.container {\n  /* position: absolute; */\n  top: 10vh;\n  left: 12vw;\n  margin: 0;\n  height: 75vh;\n  width: 75vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
