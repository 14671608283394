// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#block7 {
  width: 33.77vw;
  background-color: #b2b2b2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3vw;
}
.swatch {
  width: 3vw;
  height: 3vw;
}

#pink {
  background-color: #ffaee9;
}
#yellow {
  background-color: #fffd71;
}
#blue {
  background-color: #80fff7;
}
#white {
  background-color: white;
}
#black {
  background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Home/Block7.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;AACV;AACA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB","sourcesContent":["#block7 {\n  width: 33.77vw;\n  background-color: #b2b2b2;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 3vw;\n}\n.swatch {\n  width: 3vw;\n  height: 3vw;\n}\n\n#pink {\n  background-color: #ffaee9;\n}\n#yellow {\n  background-color: #fffd71;\n}\n#blue {\n  background-color: #80fff7;\n}\n#white {\n  background-color: white;\n}\n#black {\n  background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
