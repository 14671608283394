// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#block2 {
  display: flex;
  flex-direction: column;
  background-color: white;

  height: 100%;
  width: 40vw;
}
#row1 {
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20%;
}

#row2 {
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20%;
}

.icon {
  display: flex;
  height: 3rem;
  width: 3rem;
}
.svg {
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Home/Block2.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;;EAEvB,YAAY;EACZ,WAAW;AACb;AACA;EACE,aAAa;EACb,WAAW;EACX,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,WAAW;EACX,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;AACb;AACA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":["#block2 {\n  display: flex;\n  flex-direction: column;\n  background-color: white;\n\n  height: 100%;\n  width: 40vw;\n}\n#row1 {\n  display: flex;\n  height: 50%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  gap: 20%;\n}\n\n#row2 {\n  display: flex;\n  height: 50%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  gap: 20%;\n}\n\n.icon {\n  display: flex;\n  height: 3rem;\n  width: 3rem;\n}\n.svg {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
