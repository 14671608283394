// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Rubik-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Rubik-Light.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Rubik";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
#block8 {
  color: #ffffff;
  width: 30vw;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1rem;
}

#block8 h1 {
  background-color: transparent;
  border: none;
  text-align: left;
  padding-left: 0.5rem;

  font-family: "Rubik";
  font-weight: bold;
  font-size: 4vw;
  margin: 0.5rem;
  margin-left: 2rem;
  text-decoration: none;
}
#block8 h2 {
  background-color: transparent;
  border: none;
  text-align: left;
  padding-left: 0.5rem;
  font-family: "Rubik";
  font-weight: bold;
  font-size: 4vw;
  margin: 0.5rem;
  margin-left: 5rem;
  text-decoration: none;
}

.link {
  text-decoration: none;
}

#swe {
  color: #80fff7;
}

#art {
  color: #ffaee9;
}

#selected {
  color: #b2b2b2;
}
#unselected {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Home/Block8.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB;0DACwC;EACxC,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;;EAEpB,oBAAoB;EACpB,iBAAiB;EACjB,cAAc;EACd,cAAc;EACd,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;EACpB,iBAAiB;EACjB,cAAc;EACd,cAAc;EACd,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,YAAY;AACd","sourcesContent":["@font-face {\n  font-family: \"Rubik\";\n  src: url(\"Rubik-Light.woff2\") format(\"woff2\"),\n    url(\"Rubik-Light.woff\") format(\"woff\");\n  font-weight: 300;\n  font-style: normal;\n  font-display: swap;\n}\n#block8 {\n  color: #ffffff;\n  width: 30vw;\n  background-color: #000000;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  padding-top: 1rem;\n}\n\n#block8 h1 {\n  background-color: transparent;\n  border: none;\n  text-align: left;\n  padding-left: 0.5rem;\n\n  font-family: \"Rubik\";\n  font-weight: bold;\n  font-size: 4vw;\n  margin: 0.5rem;\n  margin-left: 2rem;\n  text-decoration: none;\n}\n#block8 h2 {\n  background-color: transparent;\n  border: none;\n  text-align: left;\n  padding-left: 0.5rem;\n  font-family: \"Rubik\";\n  font-weight: bold;\n  font-size: 4vw;\n  margin: 0.5rem;\n  margin-left: 5rem;\n  text-decoration: none;\n}\n\n.link {\n  text-decoration: none;\n}\n\n#swe {\n  color: #80fff7;\n}\n\n#art {\n  color: #ffaee9;\n}\n\n#selected {\n  color: #b2b2b2;\n}\n#unselected {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
