// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#block6 {
  width: 11.24vw;
  height: 100%;
  background-color: black;
}
#eye {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Home/Block6.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["#block6 {\n  width: 11.24vw;\n  height: 100%;\n  background-color: black;\n}\n#eye {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
