import React from "react";
import "./checker1.css";
import "./About.css";

function Checker1() {
  return (
    <div id="container">
      <div className="crow1">
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
      </div>
      <div className="crow1">
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
      </div>
      <div className="crow1">
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
        <div className="s2"></div>
        <div className="s1"></div>
      </div>
    </div>
  );
}

export default Checker1;
